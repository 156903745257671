// link generator features
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_CUSTOM_LINK_ANNOUNCEMENT = true;
export const ENABLE_CUSTOM_LINK_INVITATION = false;
export const ENABLE_LINK_ALL_GUEST = true;
export const ENABLE_SHIFT_TIME_RECEPTION = true;

// giftcard features
export const ENABLE_SEND_GIFT = true;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// special features
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;
export const ENABLE_DARKMODE_NOTIFICATION = true;
export const ENABLE_MUSLIM_INVITATION = true;
export const DEFAULT_LANGUAGE = 'id'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_COLLABORATION = false; // change to `true` if any collaboration with Invitato partner
export const ENABLE_DIFFERENT_GUEST_PARTNER_RSVP = true; // change to `true` if any different option guest partner in rsvp 

// global features
export const ENABLE_RSVP = true;
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_LIVE_STREAMING = false;
export const ENABLE_GIFT_CARD = true;
export const ENABLE_LOVE_STORY = false;
export const ENABLE_QR_INVITATION = true;
export const ENABLE_IG_FILTER = true;
export const ENABLE_QUOTES = false;
export const ENABLE_VIDEO_PREWEDDING = true;