import { DEFAULT_LANGUAGE as LANG } from './features-flag';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */

export const IS_BOY_FIRST = false;

import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */

export const GIRL_NAME = 'Winna Nur Fajar Asri';
export const GIRL_NAME_SHORT = 'Winna';
export const GIRL_FATHER_NAME = `Sadono Wiwoho, S.K.M., M.Kes.`;
export const GIRL_MOTHER_NAME = `Agustina Setyawati`;
export const GIRL_PARENT_NAME = {
  en: `The daughter of <br />Mr. ${GIRL_FATHER_NAME}<br /> and Mrs. ${GIRL_MOTHER_NAME}`,
  id: `Putri dari <br />Bapak ${GIRL_FATHER_NAME}<br /> dan Ibu ${GIRL_MOTHER_NAME}`,
}
// -> boy details
export const BOY_NAME = 'Muhammad Kasvian Kartahadinata';
export const BOY_NAME_SHORT = 'Kasvian';
export const BOY_FATHER_NAME = `H. Kasmun, S.S.`;
export const BOY_MOTHER_NAME = `Hj. Raehul Jannah, S.Pd.I.`;
export const BOY_PARENT_NAME = {
  en: `The son of <br />Mr. ${BOY_FATHER_NAME}<br /> and Mrs. ${BOY_MOTHER_NAME}`,
  id: `Putra dari <br />Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`,
}

export const THE_BRIDE = IS_BOY_FIRST 
? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
: `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}` ;
/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = '';
export const IG_GIRL = '';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = 'https://ik.imagekit.io/bxwgcojbe/winna-kesvian/seo.jpg?updatedAt=1700556300127';
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1704502800;
export const EPOCH_RESEPSI_START = 1704502800;
export const EPOCH_END_EVENT = 1704520800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-06');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '08.00 WIB - selesai';
export const WEDDING_AKAD_LOC_NAME = 'Balai Sasana Widya Praja';
export const WEDDING_AKAD_LOC_ROAD = 'Jl. Setiabudi No. 201 A, Srondol Kulon, Banyumanik, Kota Semarang, Jawa Tengah';
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD).locale(LANG).format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '11.00 - 13.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Balai Sasana Widya Praja`;
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. Setiabudi No. 201 A, Srondol Kulon, Banyumanik, Kota Semarang, Jawa Tengah`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI).locale(LANG).format('dddd, DD MMMM YYYY');
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD.MM.YYYY')

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://vianallywins.com/';
export const BROADCAST_WEDDING_LOCATION = `Balai Sasana Widya Praja, Kota Semarang`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/LGFT4W1DdMt2oxnH9`;
export const GOOGLE_MAPS_ADDRESS = `Sasana Widya Praja - BPSDMD Provinsi Jawa Tengah`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Dengarkan Dia - Bersenyawa`;
export const SOUND_URL = 'https://youtu.be/5x8Nbq0h1Ho?si=Ez6LXOjbIe2vLKrX';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/bcF5SxHatuk?si=0BOJIHZpOcY6hwAY';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/bcF5SxHatuk?si=0BOJIHZpOcY6hwAY';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbzMIOUi-rpYyv8G9svzQDurFIIO8TFE7nu-PpXji17Q_qb-mRZ6_Xd1p5wJk8gZa_Xg/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';